import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const navigation = [
  { name: 'Home', href: 'https://benevolentseniorservices.com/#home' },
  { name: 'Who We Serve', href: 'https://benevolentseniorservices.com/#serve' },
  { name: 'Services', href: 'https://benevolentseniorservices.com/#services' },
  { name: 'Our Team', href: 'https://benevolentseniorservices.com/#team' },
  { name: 'Care Options', href: 'https://benevolentseniorservices.com/#care' },
  { name: 'FAQ', href: 'https://benevolentseniorservices.com/#faq' },
  { name: 'Our Blog', href: 'https://benevolentseniorservices.com/blog' },
]

export default function Header() {
  return (
    <header className="z-10 sticky top-0 bg-mnblue">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full py-6 flex items-center justify-between border-b border-pacblue lg:border-none">
          <div className="flex items-center">
            <a href="/">
              <span className="sr-only">Workflow</span>
              <StaticImage
                src="../images/bss-icon.svg"
                width={24}
                alt="Company Icon"
                placeholder="blurred"
                className="h-10 w-auto"
              />
            </a>
            <div className="hidden ml-10 space-x-8 lg:block">
              {navigation.map((link) => (
                <AnchorLink title={link.name} to={link.href} className="text-base font-medium text-white hover:text-pacblue">
                  <span>{link.name}</span>
                </AnchorLink>
              ))}
            </div>
          </div>
          <div className="ml-0 space-x-4">
            <div
              href="#"
              className="inline-block bg-pacblue py-2 px-2 border border-transparent rounded-md text-base font-medium text-white"
            >
              833-686-4440
            </div>
            <a
              href="https://benevolentseniorservices.com/#contact"
              className="inline-block bg-yelloworange py-2 px-2 border border-transparent rounded-md text-base font-medium text-white hover:bg-engred"
            >
              CONTACT US
            </a>
          </div>
        </div>
        <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
          {navigation.map((link) => (
            <a key={link.name} href={link.href} className="text-base font-medium text-white hover:text-indigo-50">
              {link.name}
            </a>
          ))}
        </div>
      </nav>
    </header>
  )
}
